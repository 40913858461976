.site-footer {
    background-color: $black;
    color: $white;

    .column-header {
        color: $white;
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;
        text-transform: uppercase;
    }

    .footer-link {
        @extend .link-white;
    }

    .footer-address {
        font-weight: $font-weight-light;
        line-height: $line-height-md;
    }

    .footer-disclosure {
        margin-top: 30px;
        font-size: $font-size-sm;
        text-align: center;
    }
}
