.gel-quantity-selector {
    /**
        Disable default number input styles
        https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
    */
    // Chrome, Safari, Edge, Opera
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    // Firefox
    input[type=number] {
        -moz-appearance: textfield;
    }

    &--no-charge {
        .gel-quantity-selector__row--price,
        .gel-quantity-selector__row--coop-price {
            .gel-quantity-selector__field-label,
            .gel-quantity-selector__field-value {
                text-decoration: line-through;
            }
        }
    }
}

.gel-quantity-selector__row {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.gel-quantity-selector__field-value-prefix {
    margin-right: -5px; // fixes whitespace between prefix span and value span
    display: inline-block;
}

