h1, .h1 {
    line-height: 1;
}

h4, .h4 {
    font-weight: $font-weight-medium;
    line-height: 1.2;
}

h6, .h6 {
    font-weight: $font-weight-medium;
}

h5, .h5,
h6, .h6 {
    line-height: 1.4;
}

p {
    &:last-child {
        margin-bottom: 0;
    }
}

ol:not([class]),
ul:not([class]) {
    list-style: none;
    padding-left: 1rem;
    margin-bottom: $spacer;

    li {
        position: relative;
        padding-left: 1.25rem;
        font-weight: $font-weight-medium;

        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }

        &::before {
            position: absolute;
            left: 0;
            top: 0;
        }

        > ul,
        > ol {
            margin-top: 0.5rem;
        }
    }
}

ol:not([class]) {
    counter-reset: li;

    > li {
        counter-increment: li;

        $counter-width: 1.25rem;
        &::before {
            content: counter(li) '.';
            color: $black;
            font-weight: $font-weight-bold;
            left: -($counter-width / 2);
            text-align: right;
            width: $counter-width;
        }
    }
}

ul:not([class]) {
    > li {
        padding-left: 1rem;

        &::before {
            content: "";
            background-color: $cyan;
            height: 0.4375rem;
            width: 0.4375rem;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }
}

.font-weight-bolder {
    font-weight: $font-weight-bold;
}