.dropdown-toggle {
    display: flex;
    align-items: center;
    overflow-anchor: none;

    &::after {
        border: none;
        flex-shrink: 0;
        width: $accordion-icon-width;
        height: $accordion-icon-width;
        background-image: escape-svg($accordion-button-icon);
        background-repeat: no-repeat;
        background-size: $accordion-icon-width;
        margin-left: auto;
        transition: $accordion-icon-transition;
    }
}

.dropdown-toggle--no-icon {
    &::after {
        content: none;
    }
}

.dropdown-menu {
    border: none;
    box-shadow: 0 0 2px 0 $gray-200;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 4px;
        background-color: $cyan;
    }
}

.dropdown-item {
    text-transform: uppercase;
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
}
