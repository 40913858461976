.alert {
    p, ul {
        margin-bottom: 0;
    }

    li {
        list-style: disc inside;
        padding-left: 0;

        &::before {
            display: none;
        }
    }
}
