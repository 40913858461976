.btn {
    text-transform: uppercase;

    .is-admin-view & {
        text-transform: none;
    }
}

.btn-link {
    text-transform: none;
}

.btn-primary {
    &:hover,
    &:focus {
        color: $cyan;
    }
}

.btn-outline-primary {
    background-color: $white;
    color: $black;
    min-width: 150px;

    &:hover,
    &:focus {
        background-color: $primary;
        color: $cyan;
    }
}

.btn-underline-primary {
    padding: 0;
    border: none;
    border-bottom: $btn-border-width solid $primary;
    border-radius: 0;

    &:hover,
    &:focus {
        color: $primary;
    }
}

.btn--icon {
    border-radius: 0;
}

.btn--cart {
    width: 40px;
    height: 40px;
    padding: 0.5rem;

    svg {
        path {
            transition: fill .15s ease-in-out;
        }
    }

    &:hover,
    &:focus {
        svg {
            path {
                fill: $cyan;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        width: auto;
        height: auto;
        padding: $btn-padding-y $btn-padding-x;
    }
}
