$product-form-max-width: 18rem;

.gel-product__variant-select {
    max-width: $product-form-max-width;
}

.gel-product__form-quantity {
    background-color: $blue-100;
    border-radius: $border-radius-lg;
    padding: 1.5rem;
    @include media-breakpoint-up(md) {
        max-width: $product-form-max-width;
    }
}
