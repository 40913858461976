.gel-cart-items-group {
  &__dot-graphic {
    position: absolute;
    bottom: -12%;
    left: 0;

    svg {
      width: 100%;
      height: auto;

      circle {
        fill: $gray-200;
      }
    }
  }

  &__customer-po-by-type-input {
    label {
      bottom: 100%;
      left: 0;
    }
  }

  &__remove-cart-item {
    background-color: transparent;
    width: 20px;
    height: 20px;
    padding: 0;
    top: 0;
    right: 0;
    margin-top: -10px;
    margin-right: -10px;
    cursor: pointer;
    border: 0;

    .bi-x-circle-fill {
      width: 100%;
      height: 100%;
    }
  }

  &__product--destroy {
    > .row {
      @extend .bg-danger-light;
      padding: 1rem 0;

      .gel-cart-items-group__remove-message {
        display:block !important;
      }

      input, .js-quantity-selector-decrement, .js-quantity-selector-increment {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}
