.slide__body {
    .slide--product & {
        border-top: 6px solid $primary;
    }
}

.slide__img {
    .slide--product & {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-up(lg) {
            min-height: 377px;
        }
    }
}
