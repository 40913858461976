.site-header {
  .js-set-shipping-address-container {
    width: 280px;

    .dropdown-menu {
      border: none;
      border-top: 1px solid $border-color;

      .dropdown-item {
        border-bottom: 1px solid $border-color;

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

.js-set-shipping-address-container {
  input[type=search].js-search-shipping-addresses::-webkit-search-cancel-button {
    margin-right: 0!important;
  }

  .dropdown-menu {
    border: $border-width solid $border-color;
    max-height: 300px;
    overflow-y: scroll;

    &:after {
      content: none;
    }
  }

  .dropdown-item {
    white-space: unset;

    &.active, &:hover {
      background-color: $primary;
      color: $white;

      a {
        color: $white;
      }
    }

    label {
      cursor: pointer;
    }

    border-bottom:$border-width solid $border-color;

    &:last-child {
      border-bottom: 0;
    }
  }
}