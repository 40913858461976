#orders-table th a, #orders-table th a:focus, #orders-table th a:active {
    color: #fff
}

#orders-table th .current {
  padding-right: 18px;
  background-repeat: no-repeat;
  background-position: right center;
}
#orders-table th .asc {
  background-image: url('/public/up.png');
}

#orders-table th .desc {
  background-image: url('/public/down.png');
}

#my-table {
    border-collapse: collapse;
}

#my-table, #my-table th, #my-table td {
    border: 1px solid black;
    padding: 3px;
}

.order-details-container {
    width: 100%;
    display: -webkit-box;
}

.order-ref-key {
    font-weight: bold;
    font-size: 18px;
    color: black;
    margin-bottom: -20;
    padding-bottom: -20;
    margin-top: 50px;
}

