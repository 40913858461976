.page-section--location-info {
    @include media-breakpoint-up(lg) {
        background-image: url(../images/shape-circle.svg),
                          url(../images/shape-circle.svg),
                          url(../images/shape-circle.svg),
                          url(../images/shape-circle.svg),
                          url(../images/shape-circle.svg);
        background-repeat: no-repeat;
        background-position: 1300px -125px,
                             1175px 15%,
                             1295px 70%,
                             1225px 75%,
                             1200px 95%;
        background-size: 255px,
                         125px,
                         80px,
                         55px,
                         35px;
    }
}
