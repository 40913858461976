.pagination {
    align-items: center;
    justify-content: center;
}

.page-link {
    &:not(.page-link--icon) {
        font-size: $font-size-lg;
    }

    .active & {
        position: relative;
        font-weight: $font-weight-bold;

        &::after {
            content: "";
            position: absolute;
            bottom: $pagination-padding-y / 2;
            left: 50%;
            transform: translateX(-50%);
            background-color: $cyan;
            height: 3px;
            width: 1rem;
        }
    }
}

.page-link--icon {
    color: $primary;
    font-size: 1.125rem;
}

#orders-pagination .page-item.active .page-link, #orders-pagination.page-item.disabled .page-link,
#orders-pagination .page-link, #orders-pagination .page-link:focus, #orders-pagination .page-link:hover {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none;
}

