:root {
    // stylelint-disable length-zero-no-unit
    --main-scroll-bar-width: 0px;
    // stylelint-enable length-zero-no-unit
}

body,
html {
    height: 100%;
}

body {
    overflow-x: hidden; // prevents scroll bar when using .full-width
}

.site-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.site-content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding-top: $site-header-height;

    @include media-breakpoint-up(lg) {
        padding-top: $site-header-height-lg;
    }
}

.full-width {
    width: 100vw;
    margin: 0 calc(-50vw + 50%);

    .has-scrollbar & {
        width: calc(100vw - var(--main-scroll-bar-width));
        margin: 0 calc(-50vw + 50% + (var(--main-scroll-bar-width) / 2));
    }
}

.page-section__title {
    background-color: $primary;
    color: $white;
    display: inline-block;
    min-width: 187px;
    padding: 0.25rem 0.75rem;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
}

.page-section__title--light {
    background-color: $white;
    color: $primary;
}

.rounded-lg {
    border-radius: $border-radius-lg;
}

.contain-background-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.bg-danger-light {
    background-color: shift-color($danger, $alert-bg-scale);
    border:$alert-border-width solid shift-color($danger, $alert-border-scale);
}
