.page-header {
    position: relative;
}

.page-header--hero {
    min-height: 560px;

    @include media-breakpoint-up(lg) {
        min-height: 650px;
    }
}

.page-header__text {
    .page-header--hero & {
        padding-left: 1rem;
        margin-bottom: 1rem;;

        @include media-breakpoint-up(lg) {
            margin-left: 6rem;
            max-width: 525px;
            padding-top: 165px;
            position: relative;
            z-index: 1;
        }
    }
}

.page-header__featured-img {
    @include media-breakpoint-up(lg) {
        position: absolute;
        right: 0;
        top: 0;
    }
}
