.nav-link {
    text-transform: uppercase;

    .navbar-nav & {
        padding-right: $container-padding-x;
        padding-left: $container-padding-x;
    }
}

.nav-link__icon {
    transition: transform .35s ease-in-out;

    [aria-expanded="true"] & {
        transform: rotate(180deg);

        .navbar-light & {
            color: $navbar-light-active-color;
        }

        .navbar-dark & {
            color: $navbar-dark-active-color;
        }
    }
}

.nav__submenu {
    @extend .navbar-nav;

    @include media-breakpoint-up(lg) {
        $link-height: ($font-size-base * $line-height-base) + ($nav-link-padding-y * 2);

        position: absolute;
        left: $navbar-nav-link-padding-x;
        top: calc(#{$link-height} + (#{$btn-border-width} * 2));
        min-width: 240px;
        border: solid 2px $gray-200;
        border-top: 4px solid $cyan;

        .navbar-light & {
            background-color:$navbar-light-submenu-bg-color;
        }

        .navbar-dark & {
            background-color: $navbar-dark-submenu-bg-color;
        }
    }
}

.nav__submenu-link {
    @extend .nav-link;

    font-size: $font-size-sm;
    padding-top: $nav-link-padding-y;
    padding-bottom: $nav-link-padding-y;
    width: 100%;

    @include media-breakpoint-up(lg) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .navbar-nav & {
        padding-left: $container-padding-x * 2;

        .navbar-light & {
            color: $navbar-light-submenu-color;
        }

        .navbar-dark & {
            color: $navbar-dark-submenu-color;
        }
    }
}

.nav-secondary {
    position: relative;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        justify-content: normal;
    }

    &::after {
        content: '';
        height: 1px;
        width: 100%;
        background-color: $light;
        position: absolute;
        bottom: 3px;
        left: 0;
    }

    .nav-link {
        color: $secondary;
        font-size: $font-size-sm;
        padding-right: 0;
        padding-left: 0;
        position: relative;

        @include media-breakpoint-up(md) {
            margin-right: 2rem;
            margin-left: 2rem;
        }

        &.active {
            font-weight: $font-weight-bold;

            &::after {
                content: '';
                height: 6px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: $cyan;
                border-radius: 5px;
                z-index: 1; // to display on top of the nav-secondary "border"
            }
        }
    }

    .nav-item {
        &:first-child {
            .nav-link {
                margin-left: 0;
            }
        }

        &:last-child {
            .nav-link {
                margin-right: 0;
            }
        }
    }
}
