.page-section--our-brands-home {
    background-image: url(../images/shape-circle.svg),
                      url(../images/shape-circle.svg),
                      url(../images/shape-circle.svg),
                      url(../images/shape-circle.svg),
                      url(../images/shape-circle.svg);
    background-repeat: no-repeat;
    background-position: -150px -508px,
                         480px 230px,
                         100px 450px,
                         600px 575px,
                         850px 525px;
    background-size: 800px,
                     250px,
                     400px,
                     175px,
                     100px;
}

.page-section--about-us-home {
    @include media-breakpoint-up(lg) {
        background-image: url(../images/shape-circle.svg),
                          url(../images/shape-circle.svg);
        background-repeat: no-repeat;
        background-position: -75px 65%,
                             30px 35%;
        background-size: 125px,
                         175px;
    }
}
