.set_shipping_addresses {
  .card {
    @include media-breakpoint-up(md) {
      width: 500px;
    }
  }

  #submit-set-shipping-address {
    min-width: auto;
  }
}