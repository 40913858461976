.filter-collapse-toggle {
    @extend .navbar-toggler;
    transform: scale(0.8);

    span {
        &:nth-child(2),
        &:nth-child(3) {
            width: 67%;
            left: 50%;
            transform: rotate(0deg) translateX(-50%);
        }

        &:nth-child(4) {
            width: 34%;
            left: 50%;
            transform: rotate(0deg) translateX(-50%);
        }
    }

    &[aria-expanded=true] {
        span {
            &:nth-child(2) {
                width: 100%;
                left: 0;
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                width: 100%;
                left: 0;
                transform: rotate(-45deg);
            }
        }
    }
}

.product-filter-collapse {
    position: absolute;
    top: 40px;
    width: 100%;
    background-color: $white;
    z-index: $zindex-dropdown;
}

.filter-options-collapse-toggle {
    background: none;
    border: none;
    border-bottom: 1px solid $light;
    width: 100%;
    position: relative;
    text-align: left;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &::after {
        content: '';
        width: 16px;
        height: 10px;
        background: transparent url(../images/icon-arrow-up.svg) no-repeat scroll 0 0;
        background-size: 100%;
        position: absolute;
        right: 0;
        top: .5rem;
        transform: rotate(-180deg);
        transition: transform 0.25s ease-in-out;
    }

    &[aria-expanded=true] {
        &::after {
            transform: rotate(0);
        }
    }
}
