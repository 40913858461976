.invalid-feedback {
    font-weight: $font-weight-medium;
}

$search-submit-width: 25px;

.search-form {
    position: relative;
}

.search-form__input {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    padding-right: 0;
    padding-left: 0;
    width: calc(100% - #{$search-submit-width} - 5px);
    font-size: $font-size-sm;

    .search-form--lg & {
        width: 100%;
        font-size: $font-size-base;
    }
}

.search-form__submit {
    position: absolute;
    bottom: 0;
    right: 0;
    width: $search-submit-width;
    height: $search-submit-width;
}

.search-form__cancel {
    position: absolute;
    right: 0;
    top: 3px; // align with form placeholder text
}

input[type=search] {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        margin-right: 2rem;
    }
}

.login-form-container {
    max-width: 864px;
    margin-left: auto;
    margin-right: auto;
}

.login-field {
    label {
        font-size: $font-size-sm;
        color: $black;
    }
}

.login-request-access {
    background-color: #eef0f2;
    margin-top: 70px;
    display: flex;
    justify-content: center;
}

.login-request-access-content {
    max-width: 864px;
}

.form-col {
    min-height: 4.5rem;
}

.form-label-confirm-password {
    padding-top: 9px;
    padding-bottom: 2px;
}
// Password strength meter color for the different levels
$strength-colors: ($red, $yellow, $teal, $teal, $green);

// Gap width between strength meter bars
$strength-gap: 6px;

.strength-meter {
    position: relative;
    height: 3px;
    background: #DDD;
    margin: 7px 0;
    border-radius: 2px;

    // Dynamically create the gap effect
    &:before,
    &:after {
        content: '';
        height: inherit;
        background: transparent;
        display: block;
        border-color: #FFF;
        border-style: solid;
        border-width: 0 $strength-gap 0;
        position: absolute;
        width: calc(20% + #{$strength-gap});
        z-index: 10;
    }

    // Dynamically create the gap effect
    &:before {
        left: calc(20% - #{($strength-gap / 2)});
    }

    // Dynamically create the gap effect
    &:after {
        right: calc(20% - #{($strength-gap / 2)});
    }
}

.strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;

    // Dynamically generate strength meter color styles
    @for $i from 1 through 5 {
        &[data-strength='#{$i - 1}'] {
            width: (20% * $i);
            background: nth($strength-colors, $i);
        }
    }
}
