.navbar-brand {
    width: 167px;

    @include media-breakpoint-up(lg) {
        width: 250px;
    }

    @include media-breakpoint-up(xl) {
        width: 338px;
    }
}

.navbar-collapse {
    @extend .full-width;

    @include media-breakpoint-up(lg) {
        width: auto;
        margin: 0;

        .has-scrollbar & {
            width: auto;
            margin: 0;
        }
    }
}

.navbar-collapse__sizer {
    // height: calc(100vh - #{$site-header-height});
    overflow-y: auto;
    box-shadow: 0 3px 3px -2px $light;

    @include media-breakpoint-up(lg) {
        height: auto;
        overflow-y: unset;
        box-shadow: none;
    }
}

.navbar-nav--primary {
    border-top: 4px solid $cyan;

    @include media-breakpoint-up(lg) {
        border-top: none;
    }
}

.navbar-toggler {
    width: 28px;
    height: 20px;
    padding: 0;
    position: relative;
    border: none;

    $bar-height: 4px;
    span {
        display: block;
        position: absolute;
        height: $bar-height;
        width: 100%;
        background: $primary;
        left: 0;
        transform: rotate(0deg);
        transition: all 0.25s ease-in-out;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: calc(50% - (#{$bar-height} / 2));
        }

        &:nth-child(4) {
            top: auto;
            bottom: 0;
        }
    }

    &[aria-expanded=true] {
        span {
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }
}

.navbar__search-form {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $zindex-popover;
    display: none;
    min-width: 600px;
}

.navbar__search-form--active {
    display: block;
}
